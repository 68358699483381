import React, { useState, useEffect } from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import DataTable from "../newtable";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 140,
    },
}));

const MicroServicesMaster = () => {
    const classes = useStyles();
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const encodedData = localStorage.getItem('filtersData');
    const decodedData = atob(encodedData);
    const result = JSON.parse(decodedData);
    const email = result.email;
    const contacts=result.contacts;
    const appLists = result.app_list;
    const devopsGroupsData = result.devops_group;
    const MSMUrl = `${baseUrl}resource/microservicesmaster/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
    const [appList, setAppList] = useState([]);
    const [devopsGroups, setDevopsGroups] = useState([]);
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(`${MSMUrl}?email=${encodeURIComponent(email)}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
                
            });
            const data = await response.json();
            setData(data);
        };

        fetchData();
    }, []);

    return (
        <Box m="20px">
            
            <DataTable res={data} title="Micro Services Master Data" appLists={appLists} />
        </Box>
    );
};

export default MicroServicesMaster;
