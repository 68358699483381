import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Checkbox, TextField, FormControl } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import dayjs from 'dayjs';
import DataTable from 'pages/newtable';
import TagRenderer from 'components/TagRenderer';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
    maxWidth: 400,
  },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CFUsage = () => {
  const classes = useStyles();
  const location = useLocation();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const encodedData = localStorage.getItem('filtersData');
  const decodedData = atob(encodedData);
  const result = JSON.parse(decodedData);

  const email = result.email;
  const appRowKeyList = result.app_list;
  const cfTypeList = result.cf_types;
  const distributionIdList = result.distribution_ids;

  const cfUsageUrl = `${baseUrl}resource/cfusage/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
  const storedFilters = JSON.parse(localStorage.getItem("awsFilters")) || {};
  const [startDate, setStartDate] = useState(storedFilters.startDate ? dayjs(storedFilters.startDate) : dayjs().subtract(1, "month"));
  const [endDate, setEndDate] = useState(storedFilters.endDate ? dayjs(storedFilters.endDate) : dayjs());
  const [appRowKeys, setAppRowKeys] = useState(storedFilters.appRowKeys || []);
  const [cfTypes, setCfTypes] = useState(storedFilters.cfTypes || ["CF_MON"]);
  const [distributionIds, setDistributionIds] = useState(storedFilters.distributionIds || []);
  const [data, setData] = useState([]);
  // Save filters to localStorage whenever they change
    useEffect(() => {
      const filtersToStore = {
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
        appRowKeys,
        cfTypes,
        distributionIds,
      };
      localStorage.setItem("awsFilters", JSON.stringify(filtersToStore));
    }, [startDate, endDate, appRowKeys, cfTypes, distributionIds]);
  
    // Clear filters from localStorage when route changes
    useEffect(() => {
        localStorage.removeItem("awsFilters");
    }, [location.pathname]);
  const fetchData = async () => {
    try {
      const response = await fetch(`${cfUsageUrl}?email=${encodeURIComponent(email)}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          startdate: startDate.format('YYYY-MM-DD'),
          enddate: endDate.format('YYYY-MM-DD'),
          app_row_key_ids: appRowKeys.includes('Select All') ? appRowKeyList : appRowKeys,
          cf_types: cfTypes,
          distribution_ids: distributionIds.includes('Select All') ? distributionIdList : distributionIds,
        }),
      });
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.error('Error fetching CF Usage data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [startDate, endDate, appRowKeys, cfTypes, distributionIds]);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  const handleSelectAll = (newValue, list, setList, allItems) => {
    if (Array.isArray(newValue)) {
      if (newValue.includes('Select All')) {
        if (list.length === allItems.length) {
          setList([]); 
        } else {
          setList(allItems);
        }
      } else {
        setList(newValue); 
      }
    }
  };

  const isAllSelected = (list, allItems) => list.length === allItems.length;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box m="20px">
        <form>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <DatePicker
              id="start-date"
              label="Start Date"
              format="DD/MM/YYYY"
              type="date"
              value={startDate}
              maxDate={endDate}
              onChange={handleStartDateChange}
              sx={{ width: 140, marginTop: "8px" }}
              slotProps={{ textField: { InputProps: { size: 'small' } } }}
            />

            <DatePicker
              id="end-date"
              label="End Date"
              format="DD/MM/YYYY"
              type="date"
              value={endDate}
              maxDate={dayjs()}
              onChange={handleEndDateChange}
              sx={{ width: 140, marginTop: "8px" }}
              slotProps={{ textField: { InputProps: { size: 'small' } } }}
            />

            <FormControl className={classes.formControl}>
              <Autocomplete
                multiple
                id="app-row-keys"
                size="small"
                options={['Select All', ...appRowKeyList]}
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={option === 'Select All' ? isAllSelected(appRowKeys, appRowKeyList) : selected}
                    />
                    {option}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="App Row Keys" />
                )}
                value={appRowKeys}
                
                onChange={(event, newValue) => {
                  handleSelectAll(newValue, appRowKeys, setAppRowKeys, appRowKeyList);
                }}
                renderTags={(value, getTagProps) => (
                  <TagRenderer value={value} getTagProps={getTagProps} />
                )}
              />
            </FormControl>

            <FormControl className={classes.formControl}>
              <Autocomplete
                multiple
                id="cf-types"
                size="small"
                options={cfTypeList}
                disableCloseOnSelect
                getOptionLabel={(option) => option.label}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.label}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="CF Types" />
                )}
                value={cfTypeList.filter(option => cfTypes.includes(option.value))}
                onChange={(event, newValue) => {
                  const selectedValues = newValue.map((option) => option.value);
                  setCfTypes(selectedValues);
                }}
              />
            </FormControl>

            <FormControl className={classes.formControl}>
              <Autocomplete
                multiple
                id="distribution-ids"
                size="small"
                options={['Select All', ...distributionIdList]}
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={option === 'Select All' ? isAllSelected(distributionIds, distributionIdList) : selected}
                    />
                    {option}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Distribution IDs" />
                )}
                value={distributionIds}

                onChange={(event, newValue) => {
                  handleSelectAll(newValue, distributionIds, setDistributionIds, distributionIdList);
                }}
                renderTags={(value, getTagProps) => (
                  <TagRenderer value={value} getTagProps={getTagProps} />
                )}
              />
            </FormControl>
          </Box>
        </form>
      </Box>
      <DataTable res={data} title="CF Usage Data" />
    </LocalizationProvider>
  );
};

export default CFUsage;