import React, { useState, useEffect } from 'react';
import { Box, FormControl, Checkbox, TextField, Autocomplete } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import TagRenderer from 'components/TagRenderer';
import DataTable from "pages/newtable";
const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 250,
        maxWidth: 400,
    },
}));

const Pipeline = () => {
    const classes = useStyles();
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const encodedData = localStorage.getItem('filtersData');
    const decodedData = atob(encodedData);
    const result = JSON.parse(decodedData);
    const email = result.email;
    const serviceLists = result.service_name;
    const appLists = result.app_list;
    const appGroupsData = result.app_group;
    const jobTypes = result.job_type;
    const cicdTechstackOptions = result.cicd_techstack;
    const statusOptions = result.status_options
    const pipelineUrl = `${baseUrl}resource/pipeline/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;

    const [data, setData] = useState([]);
    const [selectedServices, setSelectedServices] = useState([]);
    const [appList, setAppList] = useState([]);
    const [appGroups, setAppGroups] = useState([]);
    const [jobType, setJobType] = useState([]);
    const [cicdTechstack, setCicdTechstack] = useState([]);
    const [status, setStatus] = useState([]);
    const labels = jobTypes.map(item => item.label);
    const cicdtechstacklabels = cicdTechstackOptions.map(item => item.label);
    const statuslabels = statusOptions.map(item => item.label);
    
    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(`${pipelineUrl}?email=${encodeURIComponent(email)}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                   service: selectedServices.includes('Select All') ? serviceLists : selectedServices,
                   app_list: appList.includes('Select All') ? appLists : appList,
                   app_groups: appGroups.includes('Select All') ? appGroupsData : appGroups,
                   job_type: jobType.includes('Select All') ? jobTypes : jobType,
                   cicd_techstack: cicdTechstack.includes('Select All') ? cicdTechstackOptions : cicdTechstack,
                   status: status
                })
            });
            const data = await response.json();
            setData(data);
        };

        fetchData();
    }, [selectedServices, appList, appGroups, jobType, cicdTechstack, status]);

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const handleSelectAll = (newValue, list, setList, allItems) => {
        if (Array.isArray(newValue)) {
          if (newValue.includes('Select All')) {
            if (list.length === allItems.length) {
              setList([]); 
            } else {
              setList(allItems);
            }
          } else {
            setList(newValue); 
          }
        }
    };
    
    const isAllSelected = (list, allItems) => list.length === allItems.length;

    return (
        <Box m="20px">
           <FormControl className={classes.formControl} >
                <Autocomplete
                    multiple
                    size="small"
                    options={(serviceLists ?? []).length > 0 ? ['Select All', ...serviceLists] : []}
                    disableCloseOnSelect
                    value={selectedServices}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={option === 'Select All' ? isAllSelected(selectedServices, serviceLists) : selected}
                            />
                            {option}
                        </li>
                    )}
                    renderInput={(params) => <TextField {...params} label="Services" />}
                    onChange={(event, newValue) => {
                        if ((serviceLists ?? []).length > 0) {
                            handleSelectAll(newValue, selectedServices, setSelectedServices, serviceLists);
                        } else {
                            setSelectedServices([]); 
                        }
                      }}
                    renderTags={(value, getTagProps) => (
                        <TagRenderer value={value} getTagProps={getTagProps} />
                      )}
                />
           </FormControl>
           <FormControl className={classes.formControl} >
                <Autocomplete
                    multiple
                    size="small"
                    options={['Select All', ...appLists]}
                    disableCloseOnSelect
                    value={appList}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={option === 'Select All' ? isAllSelected(appList, appLists) : selected}
                            />
                            {option}
                        </li>
                    )}
                    renderInput={(params) => <TextField {...params} label="Applications" />}
                    onChange={(event, newValue) => {
                        handleSelectAll(newValue, appList, setAppList, appLists);
                      }}
                    renderTags={(value, getTagProps) => (
                        <TagRenderer value={value} getTagProps={getTagProps} />
                      )}
                />
            </FormControl>
            <FormControl className={classes.formControl}>
                <Autocomplete
                    multiple
                    id="app-groups"
                    size="small"
                    options={[{ value: 'Select All', label: 'Select All' }, ...appGroupsData]}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={option.value === 'Select All' ? isAllSelected(appGroups, appGroupsData) : selected}
                        />
                        {option.label}
                    </li>
                    )}
                    renderInput={(params) => (
                    <TextField {...params} label="App Groups" />
                    )}
                    value={appGroups.includes('Select All') ? [{ value: 'Select All', label: 'Select All' }]: appGroupsData.filter(option => appGroups.includes(option.value))}
                    onChange={(event, newValue) => {
                    if (newValue.some(option => option.value === 'Select All')) {
                        if (appGroups.length === appGroupsData.length) {
                        setAppGroups([]); 
                        } else {
                        setAppGroups(appGroupsData.map(group => group.value));
                        }
                    } else {
                        const selectedValues = newValue.map(option => option.value);
                        setAppGroups(selectedValues);
                    }
                    }}
                    renderTags={(value, getTagProps) => (
                    <TagRenderer value={value} getTagProps={getTagProps} />
                    )}
                />
            </FormControl>
            <FormControl className={classes.formControl}>
                <Autocomplete
                    multiple
                    id="job-type"
                    size="small"
                    options={[{ value: 'Select All', label: 'Select All' }, ...jobTypes]}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={option.value === 'Select All' ? isAllSelected(jobType, jobTypes) : selected}
                            />
                            {option.label}
                        </li>
                    )}
                    renderInput={(params) => (<TextField {...params} label="Job Type" />)}
                    value={jobType.includes('Select All') ? [{ value: 'Select All', label: 'Select All' }]: jobTypes.filter(option => jobType.includes(option.value))}
                    onChange={(event, newValue) => {
                    if (newValue.some(option => option.value === 'Select All')) {
                        if (jobType.length === jobTypes.length) {
                            setJobType([]); 
                        } else {
                            setJobType(jobTypes.map(group => group.value));
                        }
                    } else {
                        const selectedValues = newValue.map(option => option.value);
                        setJobType(selectedValues);
                    }
                    }}
                    renderTags={(value, getTagProps) => (
                    <TagRenderer value={value} getTagProps={getTagProps} />
                    )}
                />
            </FormControl>
            <FormControl className={classes.formControl}>
                <Autocomplete
                    multiple
                    id="cicd-techstack"
                    size="small"
                    options={[{ value: 'Select All', label: 'Select All' }, ...cicdTechstackOptions]}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={option.value === 'Select All' ? isAllSelected(cicdTechstack, cicdTechstackOptions) : selected}
                            />
                            {option.label}
                        </li>
                    )}
                    renderInput={(params) => (<TextField {...params} label="CICD TechStack" />)}
                    value={cicdTechstack.includes('Select All') ? [{ value: 'Select All', label: 'Select All' }]: cicdTechstackOptions.filter(option => cicdTechstack.includes(option.value))}
                    onChange={(event, newValue) => {
                    if (newValue.some(option => option.value === 'Select All')) {
                        if (cicdTechstack.length === cicdTechstackOptions.length) {
                            setCicdTechstack([]); 
                        } else {
                            setCicdTechstack(cicdTechstackOptions.map(group => group.value));
                        }
                    } else {
                        const selectedValues = newValue.map(option => option.value);
                        setCicdTechstack(selectedValues);
                    }
                    }}
                    renderTags={(value, getTagProps) => (
                    <TagRenderer value={value} getTagProps={getTagProps} />
                    )}
                />
            </FormControl>
            <FormControl className={classes.formControl}>
                <Autocomplete
                    multiple
                    id="status"
                    size="small"
                    options={statusOptions}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        />
                        {option.label}
                    </li>
                    )}
                    renderInput={(params) => (
                    <TextField {...params} label="Status" />
                    )}
                    value={statusOptions.filter(option => status.includes(option.value))}
                    onChange={(event, newValue) => {
                    const selectedValues = newValue.map(option => option.value);
                    setStatus(selectedValues);
                    }}
                />
            </FormControl>
            <DataTable res={data} title="Pipeline Data" service={serviceLists} appLists={appLists} jobType={labels} cicdTechstack={cicdtechstacklabels} status={statuslabels}/>
        </Box>
    );
};

export default Pipeline;
