import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, FormControl, InputLabel, TextField, Checkbox, ListItemText } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Autocomplete from '@mui/material/Autocomplete';
import DataTable from 'pages/newtable';
import MyResponsiveLine from 'charts/NewLine';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import TagRenderer from 'components/TagRenderer';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
    maxWidth: 400,
  },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AWSVendorReport = () => {
  const classes = useStyles();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const encodedData = localStorage.getItem('filtersData');
  const decodedData = atob(encodedData);
  const result = JSON.parse(decodedData);
  const email = result.email;
  const accountLists = result.accounts;
  const columnNames = result.aws_columns;
  const awsBillingUrl = `${baseUrl}resource/awsvendorbillingreport/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;

  const [startMonth, setStartMonth] = useState(dayjs().subtract(3, 'month'));
  const [endMonth, setEndMonth] = useState(dayjs());
  const [selectedAccount, setSelectedAccount] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [groupBy, setGroupBy] = useState('Accounts'); // New groupBy state
  const [data, setData] = useState([]);
  const [transformedData, setTransformedData] = useState([]);
  function groupByAllAccounts(data) {
      const dateWiseSummary = new Map();
      data.forEach(entry => {
          const { date, ...metrics } = entry;
  
          if (!dateWiseSummary.has(date)) {
              dateWiseSummary.set(date, {});
          }
  
          const dateMetrics = dateWiseSummary.get(date);
  
          for (const [key, value] of Object.entries(metrics)) {
              const numericValue = parseFloat(value) || 0;
              dateMetrics[key] = (dateMetrics[key] || 0) + numericValue;
          }
      });
  
      const result = Array.from(dateWiseSummary.entries()).map(([date, metrics]) => ({
          date,
          ...Object.fromEntries(
              Object.entries(metrics).map(([key, value]) => [key, value.toFixed(2)]) // Format values
          ),
      }));
  
      return result;
  }
  const fetchData = async () => {
    try {
      const response = await fetch(`${awsBillingUrl}?email=${encodeURIComponent(email)}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          start_month: startMonth.format('M-YYYY'),
          end_month: endMonth.format('M-YYYY'),
          account: selectedAccount,
          columns: selectedColumns.includes('Select All') ? columnNames : selectedColumns,
        }),
      });
      const data = await response.json();
      setData(data);

      function transformData(data, metric, group_by) {
        const result = [];
        if (selectedAccount.includes("Sum All")) {
          const dateWiseSummary = groupByAllAccounts(data);
          dateWiseSummary.forEach(entry => {
            Object.keys(entry).forEach(key => {
              if (key !== "account" && key !== "date") {
                let existingEntry = result.find(e => e.id === key);
                if (!existingEntry) {
                  existingEntry = {
                    id: key,
                    color: `hsl(${Math.floor(Math.random() * 360)}, 70%, 50%)`,
                    data: [],
                  };
                  result.push(existingEntry);
                }
      
                existingEntry.data.push({
                  x: entry.date,
                  y: parseFloat(entry[key]),
                });
              }
            });
          });
      
          result.forEach(entry => {
            entry.data.sort((a, b) => new Date(a.x) - new Date(b.x));
          });
      
          return result;
        } else if (group_by === "Accounts") {
          data.forEach(accountData => {
            const account = accountData.account;
            const date = accountData.date;
      
            let existingEntry = result.find(entry => entry.id === account);
      
            if (!existingEntry) {
              existingEntry = {
                id: account,  // Grouping by account
                color: `hsl(${Math.floor(Math.random() * 360)}, 70%, 50%)`,
                data: [],
              };
              result.push(existingEntry);
            }
      
            existingEntry.data.push({
              x: date,
              y: parseFloat(accountData[metric]) 
            });
          });
      
          result.forEach(entry => {
            entry.data.sort((a, b) => new Date(a.x) - new Date(b.x));
          });
      
          return result;
        } else if (group_by === "Columns") {
          data.forEach(accountData => {
            const date = accountData.date;
      
            Object.keys(accountData).forEach(key => {
              if (key !== "account" && key !== "date") {
                let existingEntry = result.find(entry => entry.id === key);
      
                if (!existingEntry) {
                  existingEntry = {
                    id: key,
                    color: `hsl(${Math.floor(Math.random() * 360)}, 70%, 50%)`,
                    data: [],
                  };
                  result.push(existingEntry);
                }
      
                existingEntry.data.push({
                  x: date,
                  y: parseFloat(accountData[key]),
                });
              }
            });
          });
          // Sort the data by date in both cases
          result.forEach(entry => {
            entry.data.sort((a, b) => new Date(a.x) - new Date(b.x));
          });
      
          return result;
        }
        return result;
      }
      const transformedData = transformData(data, selectedColumns, groupBy);
      setTransformedData(transformedData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [startMonth, endMonth, selectedAccount, selectedColumns, groupBy]);

  useEffect(() => {
    if (groupBy === 'Accounts') {
      setSelectedColumns(['aws_usage']);
      // setSelectedAccount([]);
    } else if (groupBy === 'Columns') {
      setSelectedColumns([]);
      setSelectedAccount(['isha-prod']);
    }
  }, [groupBy]);

  const handleSelectAll = (newValue, list, setList, allItems) => {
    if (Array.isArray(newValue)) {
      if (newValue.includes('Select All')) {
        if (list.length === allItems.length) {
          setList([]); 
        } else {
          setList(allItems);
        }
      } else {
        setList(newValue); 
      }
    }
  };

  const isAllSelected = (list, allItems) => list.length === allItems.length;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box m="20px">
        <form>
          <Box sx={{ display: 'flex', flexDirection: 'row'}}>
            <DatePicker
              id="start-month"
              label="Start Month"
              views={['year', 'month']}
              format="MM-YYYY"
              maxDate={dayjs()}
              value={startMonth}
              onChange={(date) => setStartMonth(date)}
              sx={{ width: 120, marginTop: '8px' }}
              slotProps={{ textField: { InputProps: { size: 'small' } } }}
            />

            <DatePicker
              id="end-month"
              label="End Month"
              views={['year', 'month']}
              format="MM-YYYY"
              maxDate={dayjs()}
              value={endMonth}
              onChange={(date) => setEndMonth(date)}
              sx={{ width: 120, marginTop: '8px' }}
              slotProps={{ textField: { InputProps: { size: 'small' } } }}
            />

            {/* GroupBy Dropdown */}
            <FormControl className={classes.formControl}>
              <Autocomplete
                id="group-by"
                size="small"
                options={['Accounts', 'Columns']}
                value={groupBy}
                onChange={(event, newValue) => setGroupBy(newValue)}
                renderInput={(params) => <TextField {...params} label="Group By" />}
              />
            </FormControl>

            {/* Conditionally render Accounts or Columns */}
            {groupBy === 'Accounts' ? (
              <>
                <FormControl className={classes.formControl}>
                  <Autocomplete
                    multiple
                    size="small"
                    options={['Select All','Sum All', ...accountLists]}
                    disableCloseOnSelect
                    value={selectedAccount}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={option === 'Select All' ? isAllSelected(selectedAccount, accountLists) : selected}
                        />
                        {option}
                      </li>
                    )}
                    renderInput={(params) => <TextField {...params} label="Accounts" />}
                    onChange={(event, newValue) => {
                      handleSelectAll(newValue, selectedAccount, setSelectedAccount, accountLists);
                    }}
                    renderTags={(value, getTagProps) => (
                      <TagRenderer value={value} getTagProps={getTagProps} />
                    )}
                  />
                </FormControl>

                <FormControl className={classes.formControl}>
                  <Autocomplete
                    id="columns-autocomplete"
                    size="small"
                    options={columnNames}
                    value={selectedColumns[0] || ''}
                    onChange={(event, newValue) => setSelectedColumns([newValue])}
                    renderInput={(params) => (
                      <TextField {...params} label="Columns" placeholder="Select Column" />
                    )}
                  />
                </FormControl>
              </>
            ) : (
              <>
                <FormControl className={classes.formControl}>
                  <Autocomplete
                    id="accounts-autocomplete"
                    size="small"
                    options={['Sum All',...accountLists]}
                    value={selectedAccount[0] || ''}
                    onChange={(event, newValue) => setSelectedAccount([newValue])}
                    renderInput={(params) => (
                      <TextField {...params} label="Accounts" placeholder="Select Account" />
                    )}
                  />
                </FormControl>

                <FormControl className={classes.formControl}>
                  <Autocomplete
                    multiple
                    size="small"
                    options={['Select All', ...columnNames]}
                    disableCloseOnSelect
                    value={selectedColumns}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={option === 'Select All' ? isAllSelected(selectedColumns, columnNames) : selected}
                        />
                        {option}
                      </li>
                    )}
                    renderInput={(params) => <TextField {...params} label="Columns" />}
                    onChange={(event, newValue) => {
                      handleSelectAll(newValue, selectedColumns, setSelectedColumns, columnNames);
                    }}
                    renderTags={(value, getTagProps) => (
                      <TagRenderer value={value} getTagProps={getTagProps} />
                    )}
                  />
                </FormControl>
              </>
            )}
          </Box>
        </form>
      {transformedData.length > 0 && (
        <Box sx={{ height: 500 }}>
          <MyResponsiveLine data={transformedData} />
        </Box>
      )}
      </Box>
      <DataTable res={data} title="AWS Vendor Billing Report"/>
    </LocalizationProvider>
  );
};

export default AWSVendorReport;
