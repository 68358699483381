import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Checkbox, TextField, FormControl,Chip } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import dayjs from 'dayjs';
import DataTable from 'pages/newtable';
import TagRenderer from 'components/TagRenderer';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
    maxWidth: 400,
  },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const CostAnomaly = () => {
  const classes = useStyles();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const encodedData = localStorage.getItem('filtersData');
  const decodedData = atob(encodedData);
  const result = JSON.parse(decodedData);
  const email = result.email;
  const appLists = result.app_list;
  const accountLists = result.accounts;
  const costAnomalyUrl = `${baseUrl}resource/costanomaly/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [appList, setAppList] = useState([]);
  const devopsGroupsData = result.devops_group;
  const [devopsGroups, setDevopsGroups] = useState([]);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [data, setData] = useState([]);


  const fetchData = async () => {
    const app_list = appList.length > 0 ? appList : appLists;
    try {
      const response = await fetch(`${costAnomalyUrl}?email=${encodeURIComponent(email)}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          app_list: app_list.includes('Select All') ? appLists : app_list,
          startdate: startDate.format('YYYY-MM-DD'),
          enddate: endDate.format('YYYY-MM-DD'),
          account: selectedAccounts.includes('Select All') ? accountLists : selectedAccounts,
          devops_groups: devopsGroups.includes('Select All') ? devopsGroupsData : devopsGroups,
        }),
      });
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [startDate, endDate, appList, selectedAccounts, devopsGroups]);

  useEffect(() => {
    const currentDate = dayjs().subtract(2, 'day');
    const lastMonth = dayjs().subtract(2, 'day');
    setStartDate(lastMonth);
    setEndDate(currentDate);
  }, []);

  const handleSelectAll = (newValue, list, setList, allItems) => {
    if (Array.isArray(newValue)) {
      if (newValue.includes('Select All')) {
        if (list.length === allItems.length) {
          setList([]); 
        } else {
          setList(allItems);
        }
      } else {
        setList(newValue); 
      }
    }
  };

  const isAllSelected = (list, allItems) => list.length === allItems.length;
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box m="20px">
        <form>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <DatePicker
              id="start-date"
              label="Start Date"
              format="DD/MM/YYYY"
              type="date"
              value={startDate}
              maxDate={endDate}
              onChange={handleStartDateChange}
              sx={{ width: 140, marginTop: "8px", }}
              slotProps={{ textField: { InputProps: { size: 'small' } } }}
            />

            <DatePicker
              id="end-date"
              label="End Date"
              format="DD/MM/YYYY"
              type="date"
              value={endDate}
              maxDate={dayjs().subtract(2, 'day')}
              onChange={handleEndDateChange}
              sx={{ width: 140, marginTop: "8px" }}
              slotProps={{ textField: { InputProps: { size: 'small' } } }}
            />

            <FormControl className={classes.formControl}>
              <Autocomplete
                multiple
                id="app-list"
                size="small"
                options={['Select All', ...appLists]}
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={option === 'Select All' ? isAllSelected(appList, appLists) : selected}
                    />
                    {option}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Applications" />
                )}
                value={appList}
                onChange={(event, newValue) => {
                  handleSelectAll(newValue, appList, setAppList, appLists);
                }}
                renderTags={(value, getTagProps) => (
                  <TagRenderer value={value} getTagProps={getTagProps} />
                )}
              />
            </FormControl>

            <FormControl className={classes.formControl}>
              <Autocomplete
                multiple
                id="devops-groups"
                size="small"
                options={[{ value: 'Select All', label: 'Select All' }, ...devopsGroupsData]}
                disableCloseOnSelect
                getOptionLabel={(option) => option.label}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={option.value === 'Select All' ? isAllSelected(devopsGroups, devopsGroupsData) : selected}
                    />
                    {option.label}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Devops Groups" />
                )}
                value={devopsGroups.includes('Select All') ? [{ value: 'Select All', label: 'Select All' }]: devopsGroupsData.filter(option => devopsGroups.includes(option.value))}
                onChange={(event, newValue) => {
                  if (newValue.some(option => option.value === 'Select All')) {
                    if (devopsGroups.length === devopsGroupsData.length) {
                      setDevopsGroups([]); 
                    } else {
                      setDevopsGroups(devopsGroupsData.map(group => group.value));
                    }
                  } else {
                    const selectedValues = newValue.map(option => option.value);
                    setDevopsGroups(selectedValues);
                  }
                }}
                renderTags={(value, getTagProps) => (
                  <TagRenderer value={value} getTagProps={getTagProps} />
                )}
                
              />
            </FormControl>

            <FormControl className={classes.formControl}>
              <Autocomplete
                multiple
                id="accounts"
                size="small"
                options={['Select All', ...accountLists]}
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={option === 'Select All' ? isAllSelected(selectedAccounts, accountLists) : selected}
                    />
                    {option}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Accounts" />
                )}
                value={selectedAccounts}
                onChange={(event, newValue) => {
                  handleSelectAll(newValue, selectedAccounts, setSelectedAccounts, accountLists);
                }}
                renderTags={(value, getTagProps) => (
                  <TagRenderer value={value} getTagProps={getTagProps} />
                )}
              />
            </FormControl>
          </Box>
        </form>
      </Box>
      <DataTable res={data} title="Cost Anomaly Data" />
    </LocalizationProvider>
  );
};

export default CostAnomaly;