import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Box, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import DataTable from 'pages/newtable';
import MyResponsiveLine from 'charts/NewLine';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import TagRenderer from 'components/TagRenderer';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 140,
    maxWidth: 150,
  },
  formControl2: {
    margin: theme.spacing(1),
    minWidth: 250,
    maxWidth: 400,
  },
}));
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const SaasBillingReport = () => {
  const classes = useStyles();
  const location = useLocation();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const encodedData = localStorage.getItem('filtersData');
  const decodedData = atob(encodedData);
  const result = JSON.parse(decodedData);
  const email = result.email;
  const appLists = result.app_list;
  const accountLists = result.saas_accounts;
  const serviceLists=result.saas_services;
  const saasBillingUrl = `${baseUrl}resource/saasbillingreport/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
  const storedFilters = JSON.parse(localStorage.getItem("awsFilters")) || {};
  const [startMonth, setStartMonth] = useState(storedFilters.startMonth ? dayjs(storedFilters.startMonth) : dayjs().subtract(3, "month"));
  const [endMonth, setEndMonth] = useState(storedFilters.endMonth ? dayjs(storedFilters.endMonth) : dayjs());
  const [appList, setAppList] = useState(storedFilters.appList || []);
  const [selectedServices, setSelectedServices] = useState(storedFilters.selectedServices || []);
  const [selectedAccounts, setSelectedAccounts] = useState(storedFilters.selectedAccounts || []);
  const [groupby, setGroupBy] = useState(storedFilters.groupBy || 'Application');
  const [data, setData] = useState([]);
  const [transformedData, setTransformedData] = useState([]);
  
  // Save filters to localStorage whenever they change
    useEffect(() => {
      const filtersToStore = {
        startMonth: startMonth.format("YYYY-MM"),
        endMonth: endMonth.format("YYYY-MM"),
        appList,
        selectedServices,
        selectedAccounts,
        groupby,
      };
      localStorage.setItem("awsFilters", JSON.stringify(filtersToStore));
    }, [startMonth, endMonth, appList, selectedServices, selectedAccounts, groupby]);
  
    // Clear filters from localStorage when route changes
    useEffect(() => {
        localStorage.removeItem("awsFilters");
    }, [location.pathname]);

  const fetchData = async () => {
    const app_list = appList.length > 0 ? appList : appLists;
    try {
      const response = await fetch(`${saasBillingUrl}?email=${encodeURIComponent(email)}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          application: app_list.includes('Select All') ? appLists : app_list,
          start_month: startMonth.format('YYYY-MM-DD'),
          end_month: endMonth.format('YYYY-MM-DD'),
          account: selectedAccounts.includes('Select All') ? accountLists : selectedAccounts,
          groupby,
          service: selectedServices.includes('Select All') ? serviceLists : selectedServices,
        }),
      });
      const data = await response.json();
      setData(data);
      function transformData(data, groupBy) {
        const result = [];
        const colorMap = {};
        data.forEach(item => {
          let group;
          if (groupBy === "Account") {
            group = item.saas_account;
          } else if (groupBy === "Service") {
            group = item.service;
          } else {
            group = item.application;
          }
          const { payment_period, usage_cost } = item;

          let existingEntry = result.find(entry => entry.id === group);
          if (!existingEntry) {
            const color = `hsl(${Math.floor(Math.random() * 360)}, 70%, 50%)`;
            colorMap[group] = color;

            existingEntry = {
              id: group,
              color: color,
              data: []
            };
            result.push(existingEntry);
          }
          existingEntry.data.push({
            x: payment_period,
            y: parseFloat(usage_cost)
          });

          existingEntry.data.sort((a, b) => new Date(a.x) - new Date(b.x));
        });

        return result;
      }
      const transformedData = transformData(data, groupby);
      setTransformedData(transformedData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [startMonth, endMonth, appList, selectedAccounts, selectedServices, groupby]);
  const handleSelectAll = (newValue, list, setList, allItems) => {
    if (Array.isArray(newValue)) {
      if (newValue.includes('Select All')) {
        if (list.length === allItems.length) {
          setList([]); 
        } else {
          setList(allItems);
        }
      } else {
        setList(newValue); 
      }
    }
  };

  const isAllSelected = (list, allItems) => list.length === allItems.length;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box m="20px">
        <form>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <DatePicker
              id="start-month"
              label="Start Month"
              views={['year', 'month']}
              format="MM-YYYY"
              maxDate={dayjs()}
              value={startMonth}
              onChange={(date) => setStartMonth(date)}
              sx={{ width: 120, marginTop: '8px' }}
              slotProps={{ textField: { InputProps: { size: 'small' } } }}
            />

            <DatePicker
              id="end-month"
              label="End Month"
              views={['year', 'month']}
              format="MM-YYYY"
              maxDate={dayjs()}
              value={endMonth}
              onChange={(date) => setEndMonth(date)}
              sx={{ width: 120, marginTop: '8px' }}
              slotProps={{ textField: { InputProps: { size: 'small' } } }}
            />
            <FormControl className={classes.formControl} size="small">
                <InputLabel id="groupby-label">Group By</InputLabel>
                <Select
                    labelId="groupby-label"
                    id="groupby"
                    value={groupby}
                    onChange={(e) => setGroupBy(e.target.value)}
                    size="small"
                >
                    <MenuItem value="Service">Service</MenuItem>
                    <MenuItem value="Account">Account</MenuItem>
                    <MenuItem value="Application">Application</MenuItem>
                </Select>
            </FormControl>
            <FormControl className={classes.formControl2} >
                <Autocomplete
                    multiple
                    size="small"
                    options={['Select All', ...appLists]}
                    disableCloseOnSelect
                    value={appList}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={option === 'Select All' ? isAllSelected(appList, appLists) : selected}
                            />
                            {option}
                        </li>
                    )}
                    renderInput={(params) => <TextField {...params} label="Applications" />}
                    onChange={(event, newValue) => {
                        handleSelectAll(newValue, appList, setAppList, appLists);
                      }}
                    renderTags={(value, getTagProps) => (
                        <TagRenderer value={value} getTagProps={getTagProps} />
                      )}
                />
            </FormControl>
            <FormControl className={classes.formControl2} >
                <Autocomplete
                    multiple
                    size="small"
                    options={['Select All', ...accountLists]}
                    disableCloseOnSelect
                    value={selectedAccounts}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={option === 'Select All' ? isAllSelected(selectedAccounts, accountLists) : selected}
                            />
                            {option}
                        </li>
                    )}
                    renderInput={(params) => <TextField {...params} label="Accounts" />}
                    onChange={(event, newValue) => {
                        handleSelectAll(newValue, selectedAccounts, setSelectedAccounts, accountLists);
                      }}
                    renderTags={(value, getTagProps) => (
                        <TagRenderer value={value} getTagProps={getTagProps} />
                      )}
                />
            </FormControl>
            <FormControl className={classes.formControl2} >
                <Autocomplete
                    multiple
                    size="small"
                    options={['Select All', ...serviceLists]}
                    disableCloseOnSelect
                    value={selectedServices}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={option === 'Select All' ? isAllSelected(selectedServices, serviceLists) : selected}
                            />
                            {option}
                        </li>
                    )}
                    renderInput={(params) => <TextField {...params} label="Services" />}
                    onChange={(event, newValue) => {
                        handleSelectAll(newValue, selectedServices, setSelectedServices, serviceLists);
                      }}
                    renderTags={(value, getTagProps) => (
                        <TagRenderer value={value} getTagProps={getTagProps} />
                      )}
                />
            </FormControl>
          </Box>
        </form>
        {transformedData.length > 0 && (
          <Box height="250px" m="5px 0 0 0">
            <MyResponsiveLine data={transformedData} isDashboard={true} />
          </Box>
        )}
      </Box>
      <DataTable res={data} title="Saas Billing Report" />
    </LocalizationProvider>
  );
};

export default SaasBillingReport;