import React, { useState, useEffect } from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, TextField, Autocomplete } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import TagRenderer from 'components/TagRenderer';
import DataTable from "../appinfo/table";
const useStyles = makeStyles((theme) => ({
    formControl: {
        // margin: theme.spacing(1),
        marginLeft:5,
        marginTop:8,
        minWidth: 120,
        maxWidth: 140,
    },
    formControl2: {
        margin: theme.spacing(1),
        minWidth: 250,
        maxWidth: 400,
    },

}));


const Elastic = () => {
    const classes = useStyles();
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const encodedData = localStorage.getItem('filtersData');
    const decodedData = atob(encodedData);
    const result = JSON.parse(decodedData);
    const email = result.email;
    const accountLists = result.accounts;
    const elasticUrl = `${baseUrl}resource/elastic/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;

    const [data, setData] = useState([]);
    const state = result.state;
    const [stateList, setStateList] = useState([]);
    const [selectedAccounts, setSelectedAccounts] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(`${elasticUrl}?email=${encodeURIComponent(email)}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    state: stateList,
                    account: selectedAccounts.includes('Select All') ? accountLists : selectedAccounts,

                })
            });
            const data = await response.json();
            setData(data);
        };

        fetchData();
    }, [stateList, selectedAccounts]);

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const handleSelectAll = (newValue, list, setList, allItems) => {
        if (Array.isArray(newValue)) {
          if (newValue.includes('Select All')) {
            if (list.length === allItems.length) {
              setList([]); 
            } else {
              setList(allItems);
            }
          } else {
            setList(newValue); 
          }
        }
    };
    
    const isAllSelected = (list, allItems) => list.length === allItems.length;

    return (
        <Box m="20px">
            <FormControl className={classes.formControl} size="small">
                <InputLabel id="app-list-label">State</InputLabel>
                <Select
                    labelId="app-list-label"
                    id="app-list"
                    multiple
                    value={stateList}
                    onChange={(e) => setStateList(e.target.value)}
                    renderValue={(selected) => selected.join(', ')}
                    size="small"
                >
                    {state.map((app) => (
                        <MenuItem key={app} value={app}>
                            <Checkbox checked={stateList.indexOf(app) > -1} />
                            <ListItemText primary={app} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl className={classes.formControl2} >
                <Autocomplete
                    multiple
                    size="small"
                    options={['Select All', ...accountLists]}
                    disableCloseOnSelect
                    value={selectedAccounts}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={option === 'Select All' ? isAllSelected(selectedAccounts, accountLists) : selected}
                            />
                            {option}
                        </li>
                    )}
                    renderInput={(params) => <TextField {...params} label="Accounts" />}
                    onChange={(event, newValue) => {
                        handleSelectAll(newValue, selectedAccounts, setSelectedAccounts, accountLists);
                      }}
                    renderTags={(value, getTagProps) => (
                        <TagRenderer value={value} getTagProps={getTagProps} />
                      )}
                />
            </FormControl>
            <DataTable res={data} title="Elastic"  />
        </Box>
    );
};

export default Elastic;
