import React, { useState, useEffect } from 'react';
import { Box, FormControl, InputLabel, ListItemText } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import DataTable from 'pages/newtable';
import { useLocation } from 'react-router-dom';

import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import TagRenderer from 'components/TagRenderer';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
    maxWidth: 400,
  },
  dropdownContainer: {
    display: 'flex',
    gap: '20px', // Adjust the gap between dropdowns as needed
  },
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const NodePing = () => {
  const classes = useStyles();
  const location = useLocation();
  const { r } = location.state || {};
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const encodedData = localStorage.getItem('filtersData');
  const decodedData = atob(encodedData);
  const result = JSON.parse(decodedData);
  const email = result.email;
    const appLists =result.app_list;
  const devopsGroupsData = result.devops_group;
  const state = r?.state ? [r.state] : [];
  const nodePingUrl = `${baseUrl}alertmonitoring/nodepingmonitoring/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
  const [appList, setAppList] = useState([]);
  const [devopsGroups, setDevopsGroups] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`${nodePingUrl}?email=${encodeURIComponent(email)}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          applications: appList.includes('Select All') ? appLists : appList,
          devops_groups: devopsGroups.includes('Select All') ? devopsGroupsData : devopsGroups,
                    state:state
        })
      });
      const data = await response.json();
      setData(data);
    };

    fetchData();
  }, [appList, devopsGroups]);
  const handleSelectAll = (newValue, list, setList, allItems) => {
    if (Array.isArray(newValue)) {
      if (newValue.includes('Select All')) {
        if (list.length === allItems.length) {
          setList([]); 
        } else {
          setList(allItems);
        }
      } else {
        setList(newValue); 
      }
    }
  };

  const isAllSelected = (list, allItems) => list.length === allItems.length;
  return (
    <Box m="20px">
      <div className={classes.dropdownContainer}>
      <FormControl className={classes.formControl}>
        <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            size="small"
            options={['Select All', ...appLists]}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={option === 'Select All' ? isAllSelected(appList, appLists) : selected}
                />
                {option}
              </li>
            )}
            // sx={{ width: 300, maxWidth: 500 }}
            renderInput={(params) => (
              <TextField {...params} label="Applications" placeholder="Select Applications" />
            )}
            value={appList}
            onChange={(event, newValue) => {
              handleSelectAll(newValue, appList, setAppList, appLists);
            }}
            renderTags={(value, getTagProps) => (
              <TagRenderer value={value} getTagProps={getTagProps} />
            )}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
              <Autocomplete
                multiple
                id="devops-groups"
                size="small"
                options={[{ value: 'Select All', label: 'Select All' }, ...devopsGroupsData]}
                disableCloseOnSelect
                getOptionLabel={(option) => option.label}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={option.value === 'Select All' ? isAllSelected(devopsGroups, devopsGroupsData) : selected}
                    />
                    {option.label}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Devops Groups" />
                )}
                value={devopsGroups.includes('Select All') ? [{ value: 'Select All', label: 'Select All' }]: devopsGroupsData.filter(option => devopsGroups.includes(option.value))}
                onChange={(event, newValue) => {
                  if (newValue.some(option => option.value === 'Select All')) {
                    if (devopsGroups.length === devopsGroupsData.length) {
                      setDevopsGroups([]); 
                    } else {
                      setDevopsGroups(devopsGroupsData.map(group => group.value));
                    }
                  } else {
                    const selectedValues = newValue.map(option => option.value);
                    setDevopsGroups(selectedValues);
                  }
                }}
                renderTags={(value, getTagProps) => (
                  <TagRenderer value={value} getTagProps={getTagProps} />
                )}
                
              />
            </FormControl>
      </div>
      <DataTable res={data} title="NodePing Data" appLists={appLists} />
    </Box>
  );
};

export default NodePing;
