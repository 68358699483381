import React, { useState, useEffect } from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, Checkbox,  TextField, Autocomplete,ListItemText } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import DataTable from "../newtable";
import TagRenderer from 'components/TagRenderer';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 250,
        maxWidth: 400,
    },
}));

const MisAppTools = () => {
    const classes = useStyles();
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const encodedData = localStorage.getItem('filtersData');
    const decodedData = atob(encodedData);
    const result = JSON.parse(decodedData);
    const email = result.email;
    const instance_name = result.instance_name;
    const tools=result.tools;
    const appLists = result.app_list;
    const devopsGroupsData = result.devops_group;
    const MATUrl = `${baseUrl}resource/misapptools/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
    const [appList, setAppList] = useState([]);
    const [devopsGroups, setDevopsGroups] = useState([]);
    const [data, setData] = useState([]);

    const [ms_type, setmstype] = useState([]);
    const [toolsList, setTools] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(`${MATUrl}?email=${encodeURIComponent(email)}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    app: appList.includes('Select All') ? appLists : appList,
                    tool_name: toolsList.includes('Select All') ? appLists : toolsList,
                    

                })
            });
            const data = await response.json();
            setData(data);
        };

        fetchData();
    }, [appList,toolsList]);
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const handleSelectAll = (newValue, list, setList, allItems) => {
        if (Array.isArray(newValue)) {
          if (newValue.includes('Select All')) {
            if (list.length === allItems.length) {
              setList([]); 
            } else {
              setList(allItems);
            }
          } else {
            setList(newValue); 
          }
        }
      };
    
    const isAllSelected = (list, allItems) => list.length === allItems.length;

    return (
        <Box m="20px">
            <FormControl className={classes.formControl} >
                <Autocomplete
                    multiple
                    size="small"
                    options={['Select All', ...appLists]}
                    disableCloseOnSelect
                    value={appList}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={option === 'Select All' ? isAllSelected(appList, appLists) : selected}
                            />
                            {option}
                        </li>
                    )}
                    renderInput={(params) => <TextField {...params} label="Applications" />}
                    onChange={(event, newValue) => {
                        handleSelectAll(newValue, appList, setAppList, appLists);
                      }}
                    renderTags={(value, getTagProps) => (
                        <TagRenderer value={value} getTagProps={getTagProps} />
                      )}
                />
            </FormControl>
            
            <FormControl className={classes.formControl} >
                <Autocomplete
                    multiple
                    size="small"
                    options={['Select All', ...tools]}
                    disableCloseOnSelect
                    value={toolsList}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={option === 'Select All' ? isAllSelected(toolsList, tools) : selected}
                            />
                            {option}
                        </li>
                    )}
                    renderInput={(params) => <TextField {...params} label="Tool Name" />}
                    onChange={(event, newValue) => {
                        handleSelectAll(newValue, toolsList, setTools, tools);
                      }}
                    renderTags={(value, getTagProps) => (
                        <TagRenderer value={value} getTagProps={getTagProps} />
                      )}
                />
            </FormControl>
            <DataTable res={data} title="App & Tools Data" instance_name={instance_name} tool_name={tools} appLists={appLists}/>
        </Box>
    );
};

export default MisAppTools;
