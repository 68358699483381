import React, {useMemo, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Box, useMediaQuery, Button, Typography, IconButton, Tooltip,Select,InputLabel,MenuItem } from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CircularProgress from '@mui/material/CircularProgress';
const DataTable = ({ res, title, appLists, accounts, services, labels, contacts, dptype, instance_name,tool_name,mstype,serviceTypes,serviceTechstack,status,service,jobType,cicdTechstack}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const encodedData = localStorage.getItem('encodedData');
  const decodedData = atob(encodedData);
  const result = JSON.parse(decodedData);
  const email = result.email;
  const userRole= result.user_role;
  
  const nodepingSyncUrl = `${baseUrl}alertmonitoring/npSync/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
  const nodePingUpdateUrl = `${baseUrl}alertmonitoring/updatenodeping/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
  const costAnomalyUpdateUrl= `${baseUrl}resource/updatecostanomaly/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
  const costAnomalyConfigUpdateUrl= `${baseUrl}resource/updatecostanomalyconfig/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
  const costAnomalyConfigDeleteUrl= `${baseUrl}resource/deletecostanomalyconfig/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
  const appInfoUpdateUrl=`${baseUrl}resource/update_appinfo_data/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
  const squadCastUpdateUrl = `${baseUrl}alertmonitoring/updatesquadcast/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
  const instanceUpdateUrl = `${baseUrl}resource/updateinstance/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
  const domainUpdateUrl = `${baseUrl}auditmonitoring/updatedomain/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
  const sassBillingUpdateUrl = `${baseUrl}resource/updatesaasbilling/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
  const sassBillingDeleteUrl = `${baseUrl}resource/deletesaasbilling/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
  const sassInfoUpdateUrl = `${baseUrl}resource/updatesaasinfo/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
  const sassInfoDeleteUrl = `${baseUrl}resource/deletesaasinfo/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
  const vendorBillingUpdateUrl = `${baseUrl}resource/updateawsvendorbilling/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
  const vendorBillingDeleteUrl = `${baseUrl}resource/deleteawsvendorbilling/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
  const MADUpdateUrl = `${baseUrl}resource/createupdatemad/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
  const MSMUpdateUrl = `${baseUrl}resource/createupdatemsm/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
  const MATUpdateUrl = `${baseUrl}resource/createupdatemat/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
  const MASUpdateUrl = `${baseUrl}resource/createupdatemas/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
  const TMCreateUrl = `${baseUrl}resource/createtm/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
  const MADDeleteUrl = `${baseUrl}resource/deletemad/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
  const MSMDeleteUrl = `${baseUrl}resource/deletemsm/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
  const MATDeleteUrl = `${baseUrl}resource/deletemat/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
  const MASDeleteUrl = `${baseUrl}resource/deletemas/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
  const TMDeleteUrl = `${baseUrl}resource/deletetm/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
  const dep_ser = `${baseUrl}resource/get_dep_service/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
  const appServiceUpdateUrl = `${baseUrl}resource/createupdateappservice/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
  const pipelineUpdateUrl = `${baseUrl}resource/createupdatepipeline/0duQVs4ArtPmMbyLZMvW457wgPCAhSZ7YWfHtaWU`;
  const [selectOptions, setSelectOptions] = useState([]);
  const [depOptions, setDepOptions] = useState([]);
  const [editingValue, setEditingValue] = useState({});
  const data = res;
    const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
    const formatColumnName = (columnName) => {
    return columnName.replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase());
  };
  const handleCellEdit = async (column, value) => {
    try {

      setEditingValue(prevEditingValue => ({
        ...prevEditingValue,
        [column]: value
      }));
      const response = await fetch(`${dep_ser}?email=${encodeURIComponent(email)}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ dependent_app: value }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch dependent services');
      }
      
      const data = await response.json();
      column === 'app' ? setSelectOptions( data) : setDepOptions(data);
    } catch (error) {
      console.error('Error:', error);
    }
  };
  function hasWritePermission(title) {
    
    const permissionList = result.permission_list;
    if (title === "Instance Data") {
      title = "Resources Data";
    }
    const coreTitle = title.split(' ').slice(0, -1).join(' '); // Remove the last word

    const permissionListLower = permissionList.map(permission => permission.toLowerCase());

    if (permissionListLower.includes(coreTitle.toLowerCase())) {
        return true; // Has write permission
    }

    // Return false if no matching 'write' permission is found
    return false;
}
  const [loading, setLoading] = useState(false);
  const handleSyncData = async () => {
    try {
        setLoading(true);
        const response = await fetch(`${nodepingSyncUrl}?email=${encodeURIComponent(email)}`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json'
          },
          
      });

        if (!response.ok) {
            throw new Error('Failed to Sync Data');
        }

        const data = await response.json();
        alert(data.message);
        window.location.reload();
    } catch (error) {
        console.error('Error:', error);
        alert('Failed to Sync Data');
    }
    finally {
      setLoading(false); 
  }
};
  const get_data = (rowData) => {
    if (rowData === 'Tag Mismatch') {
        return {tag: 'MisMatch'};
    } 
    else if (rowData === 'Tag Missed') {
        return {tag: 'Missed'};
    } 
    else if (rowData === 'Available EBS') {
        return { status: 'available', service: 'EBS' };
    } 
    else if (rowData === 'Available EIP') {
      return { status: 'available', service: 'EIP' };
    } 
    else if (rowData === 'Stopped EC2') {
      return { status: 'stopped', service: 'EC2' };
    } 
    else if (rowData === 'Stopped RDS') {
      return { status: 'stopped', service: 'RDS' };
    }
    else if (rowData === 'VAPT EC2') {
      return { name: 'VAPT', service: 'EC2' };
    }  
    else if (rowData === 'Snapshots') {
      return {service: 'SNAPSHOT' };
    }
    else if (rowData === 'AMI') {
      return {service: 'EC2IMAGE' };
    } 
    else if (rowData === 'NodePing State') {
      return {state: '0' };
    } 
    else if (rowData === 'Failed EC2') {
      return {status: 'failed', service: 'EC2' };
    }     
    else {
        return null; 
    }
  };
  const initialState = {
    showGlobalFilter: true,
    density: 'compact',
    showColumnFilters: title !== "Cost and usage" && title !== "Consumer Data" && title !== "Cost Anomaly Data",
    columnPinning: {
      left: ['Service', 'Application', 'Account', 'TotalCost'],
    },
    pagination:  { pageSize: 25 } ,
    sorting: [
      {
        id: 'lag',
        desc: true,
      },
      {
        id: 'audit_date',
        desc: true,
      },
      {
        id: 'bytes_downloaded',
        desc: true,
      } 
    ]
  };
  const sumAwsCost = useMemo(() => {
      if (title !== "AWS App Billing Data" || data.length === 0) return 0;
      const total = data.reduce((acc, row) => acc + (row.aws_cost || 0), 0);
      return parseFloat(total.toFixed(2));
    }, [data, title]);
  
    const sumVendorCostWithTax = useMemo(() => {
      if (title !== "AWS App Billing Data" || data.length === 0) return 0;
      const total = data.reduce((acc, row) => acc + (row.vendor_cost_with_tax || 0), 0);
      return parseFloat(total.toFixed(2));
    }, [data, title]);

  const columns = data?.length > 0 ? Object.keys(data[0]).map((column) => ({
    header: formatColumnName(column),
    accessorKey: column,
    Cell: ({ row }) => {
      const cellValue = row.original[column];
      if (column === 'topic' && cellValue) {
        return (
          <a
            href="#"
            onClick={(event) => {
              event.preventDefault();
              const rowData = row.original;
              navigate('/consumer', { state: { rowData } });
            }}
            style={{ color: 'lightblue', textDecoration: 'underline', cursor: 'pointer' }}
          >
            {cellValue}
          </a>
        );
      } 
      else if (title == "One Glance" && column === 'service' && cellValue) {
        return (
          <a
            href="#"
            onClick={(event) => {
              event.preventDefault();
              const rowData = row.original.service;
              const r = get_data(rowData);
              if(rowData !== 'VPC' && rowData !== 'PEM Keys' && rowData !== 'KMS Keys' && rowData !== 'TTR Remarks'){
              const nav = rowData.includes('NodePing') ? '/nodeping' : '/resources';
              navigate(nav, { state: { r } });
              }
            }}
            style={{ color: 'lightblue', textDecoration: 'underline', cursor: 'pointer' }}
          >
            {cellValue}
          </a>
        );
      }
      else if (title == "NodePing Data" && column === 'label' && cellValue) {
        return (
          <a
            href="#"
            onClick={(event) => {
              event.preventDefault();
              const rowData = row.original;
              navigate('/nodepingresults', { state: { rowData }  });
            }}
            style={{ color: 'lightblue', textDecoration: 'underline', cursor: 'pointer' }}
          >
            {cellValue}
          </a>
        );
      }
      else if (title == "SquadCast Data" && column === 'description') {

        return (
          <div style={{ width: '850px',whiteSpace: 'normal', wordWrap: 'break-word'}}>
            {typeof cellValue === 'object' ? JSON.stringify(cellValue) : cellValue?.toString() || ''}
          </div>
        );
     
    }
      else {
        return typeof cellValue === 'object' ? JSON.stringify(cellValue) : cellValue?.toString() || '';
      }
    },
    enableEditing: (title === "NodePing Data" || title === "Cost Anomaly Data" || title === "Cost Anomaly Config Data" || title === "AppInfo Data" || title === "SquadCast Data" || title === "Instance Data" || 
      title === "SquadCast Data" || title === "Instance Data" || title === "Domains Data"  || title === "Saas Billing Data" || title === "AWS Vendor Billing Data" || title ==="Saas Tool Info Data" ||
      title === "Tools Master Data"|| title === "App & Tools Data"|| title === "Micro Services Master Data" || title==="App & Dependencies Data"  || title==="App & Services Data" ||  title==="App Service Data" ||  title==="Pipeline Data") &&
      (
        (userRole === 'Developer' && column === 'Description') ||
        (userRole !== 'Developer' && title === "NodePing Data" && ['App_Name', 'Remarks', 'Description'].includes(column)) ||
        (userRole !== 'Developer' && title === "Instance Data" && ['devops_status', 'remarks', 'review_due_date'].includes(column)) ||
        (userRole !== 'Developer' && title === "Domains Data" && ['application_id', 'description', 'status', 'dns_manager', 'dns_delegation', 'waf', 'cdn',"review_due_date","requested_by"].includes(column)) ||
        (userRole !== 'Developer' && title === "AWS Vendor Billing Data" && ['account', 'date', 'aws_usage', 'credits', 'discounts',
        'cloudfront_usage', 'cloudfront_cost', 'SP_RI_coverage', 'total_savings_lin', 'actual_savings_lin', 'total_savings_win', 'actual_savings_win'].includes(column)) ||
        (userRole !== 'Developer' && title === "Saas Billing Data" && ['saas_account', 'application', 'service', 'payment_period', 'approved_budget', 'expected_cost','usage_info', 'usage_cost', 'remarks'].includes(column)) ||
        (userRole !== 'Developer' && title === "Saas Tool Info Data" && ['saas_account', 'application', 'service', 'billing_type', 'billing_cycle', 'mfa', 'purpose', 'plan_details',
              'master_user', 'login_mode', 'renewal_date', 'card_holder', 'card_number', 'billing_address', 'tool_manager','tool_owner','account_owner','remarks'].includes(column)) ||
        ((userRole !== 'Developer') && title === "Cost Anomaly Data" && column === 'remarks')||
        ((userRole !== 'Developer') && title === "Cost Anomaly Config Data" && ['ref_amt', 'remarks', 'deviation'].includes(column)) ||
        (userRole === 'Developer' && column === 'developers') ||
        (userRole !== 'Developer' && ['devops_engineer','devops_coordinators','PM','developers'].includes(column))||
        (title === "SquadCast Data" && column === 'remarks')||
        (title === "Tools Master Data" && column === 'tool_name')||
        (title === "Micro Services Master Data" && ['app','microservices_list'].includes(column))||
        (title === "App & Services Data" && ['app','microservice','microservice_type','image_name','deployment_name','details','base_url','info'].includes(column))||
        (title === "App & Tools Data" && ['app','tool_name','instance_name','details'].includes(column))||
        (title==="App & Dependencies Data" && [ 'app','microservice','dependent_app','dependent_service', 'dependent_type', 'details'].includes(column))||
        (title === "App Service Data" && [ 'service_name', 'app_tag', 'service_type', 'image_name', 'service_techstack', 'service_reponame', 'status', 'description'].includes(column))||
        (title === "Pipeline Data" && [ 'job_name', 'service_name', 'job_type', 'image_path', 'cicd_reponame', 'app_tag', 'service_repobranch', 'cicd_branch', 'cicd_techstack', 'job_url', 'service_url', 'status', 'description'].includes(column))
      ),
      ...(column === 'App_Name' || column === 'application_id' || column === 'account' || column === 'application' || column === 'dependent_app' || column === 'app'  || column === 'app_tag') && {
        editSelectOptions: appLists,
        editVariant: 'select',
      },
      ...(column === 'status' || column === 'devops_status' || column === 'billing_type') && {
        editSelectOptions: labels,
        editVariant: 'select',
      },
      ...(column === 'saas_account') && {
        editSelectOptions: accounts,
        editVariant: 'select',
      },
      ...(column === 'service') && {
        editSelectOptions: services,
        editVariant: 'select',
      },
      ...(column === 'billing_cycle') && {
        editSelectOptions: ['monthly','yearly','credit'],
        editVariant: 'select',
      },
      ...(column === 'mfa') && {
        editSelectOptions: ['enabled','disabled'],
        editVariant: 'select',
      },
      ...(column === 'login_mode') && {
        editSelectOptions: ['password','GAuth','Gmail OTP'],
        editVariant: 'select',
      },
      ...(column === 'tool_manager' || column === 'tool_owner' || column === 'account_owner') && {
        editSelectOptions: contacts,
        editVariant: 'select',
      },
      ...(column === 'service_type') && {
        editSelectOptions: serviceTypes,
        editVariant: 'select',
      },
      ...(column === 'service_techstack') && {
        editSelectOptions: serviceTechstack,
        editVariant: 'select',
      },
      ...((title === "App Service Data" || title === "Pipeline Data" ) && column === 'status') && {
        editSelectOptions: status,
        editVariant: 'select',
      },
      ...(title === "Pipeline Data" && column === 'service_name') && {
        editSelectOptions: service,
        editVariant: 'select',
      },
      ...(column === 'job_type') && {
        editSelectOptions: jobType,
        editVariant: 'select',
      },
      ...(column === 'cicd_techstack') && {
        editSelectOptions: cicdTechstack,
        editVariant: 'select',
      },
      ...((title === "App & Services Data" || title==="App & Dependencies Data")  && ['app','dependent_app'].includes(column))&&{
        Edit: ({row}) => {
          useEffect(() => {
            const currentValue = editingValue[column] ? editingValue[column] : row.original[column];
            if (currentValue){
              //  console.log("log",currentValue);
               handleCellEdit(column, row.original[column]);
            }
          }, []);
          return (
            <>
            <InputLabel id="accounts-label">{formatColumnName(column)}</InputLabel>
            <Select
              labelId="labelId"
              id="selectId" 
              value={editingValue[column] ? editingValue[column] : row.original[column] }
              onChange={(event) => handleCellEdit(column,event.target.value)}
              MenuProps={{
                PaperProps: {
                  sx: {
                    '& .MuiMenuItem-root.Mui-selected': {
                      backgroundColor: 'blue', 
                    },
                  },
                },
              }}
            >
              {appLists.map((app) => (
                <MenuItem key={app} value={app}>
                  {app}
                </MenuItem>
              ))}
            </Select>
            </>
          );
        },
      },
      ...(column === 'dependent_type') && {
        editSelectOptions: dptype,
        editVariant: 'select',
      },
      ...(title === "MisAppTools Data" && column === 'tool_name') && {
        editSelectOptions: tool_name,
        editVariant: 'select',
      },
      ...(column === 'microservice_type') && {
        editSelectOptions: mstype,
        editVariant: 'select',
      },
      ...(column === 'instance_name') && {
        editSelectOptions: instance_name,
        editVariant: 'select',
      },
      ...(column === 'dependent_service' ) && {
        editSelectOptions: depOptions,
        editVariant: 'select',
      },
      ...( column === 'microservice') && {
        editSelectOptions: selectOptions,
        editVariant: 'select',
      },
      Footer: column === 'aws_cost' ? () => `Total: ${sumAwsCost}` : column === 'vendor_cost_with_tax' ? () => `Total: ${sumVendorCostWithTax}` : undefined,
  })) : [];
  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
  });
  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };
  const handleSaveRow = ({ exitEditingMode, values }) => {
      const fetchData = async () => {
          try {
              const response = await fetch(`${nodePingUpdateUrl}?email=${encodeURIComponent(email)}`, {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                      endpoint: values.Endpoint,
                      app_name: values.App_Name,
                      description: values.Description,
                      remarks: values.Remarks
                  })
              });

              if (!response.ok) {
                  throw new Error('Failed to update row');
              }

              const data = await response.json();
              alert(data.message);
              window.location.reload();
          } catch (error) {
              console.error('Error:', error);
              alert('Failed to update data');
          }
      };

      fetchData();
      exitEditingMode();
  };
  const handleSaveInstanceRow = ({ exitEditingMode, values }) => {
    const fetchData = async () => {
        try {
            const response = await fetch(`${instanceUpdateUrl}?email=${encodeURIComponent(email)}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  instance_id: values.instance_id,
                  service_type_key: values.service_type,
                  devops_status: values.devops_status,
                  review_due_date: values.review_due_date,
                  remarks: values.remarks
                })
            });

            if (!response.ok) {
                throw new Error('Failed to update row');
            }

            const data = await response.json();
            alert(data.message);
            window.location.reload();
        } catch (error) {
            console.error('Error:', error);
            alert('Failed to update data');
        }
    };

    fetchData();
    exitEditingMode();
};
const handleSaveVendorBillingRow = ({ exitEditingMode, values }) => {
  const fetchData = async () => {
      try {
          const response = await fetch(`${vendorBillingUpdateUrl}?email=${encodeURIComponent(email)}`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                linked_account: values.account,
                date: values.date,
                aws_consumption: values.aws_usage,
                credits: values.credits,
                discounts: values.discounts,
                cloudfront_consumption: values.cloudfront_usage,
                cloudfront_cost: values.cloudfront_cost,
                sp_ri_coverage: values.SP_RI_coverage,
                total_savings_on_linux: values.total_savings_lin,
                actual_savings_on_linux: values.actual_savings_lin,
                total_savings_on_windows: values.total_savings_win,
                actual_savings_on_windows: values.actual_savings_win
              })
          });
        
          if (response.status === 400) {
            const errorData = await response.json();
            alert(errorData.error || 'A record with the same Account and Date already exists.');
            return;
          }
          if (!response.ok) {
              throw new Error('Failed to update row');
          }

          const data = await response.json();
          alert(data.message);
          window.location.reload();
      } catch (error) {
          console.error('Error:', error);
          alert('Failed to update data');
      }
  };

  fetchData();
  // exitEditingMode();
};
const handleSaveSaasBillingRow = ({ exitEditingMode, values }) => {
  const fetchData = async () => {
      try {
          const response = await fetch(`${sassBillingUpdateUrl}?email=${encodeURIComponent(email)}`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                saas_account: values.saas_account,
                application: values.application,
                service: values.service,
                payment_period: values.payment_period,
                approved_budget: values.approved_budget,
                expected_cost:values.expected_cost,
                usage_info: values.usage_info,
                usage_cost: values.usage_cost,
                remarks: values.remarks
              })
          });
        
          if (response.status === 400) {
            const errorData = await response.json();
            alert(errorData.error || 'A record with the same Account, Application, Service and Payment Period already exists.');
            return;
          }
          if (!response.ok) {
              throw new Error('Failed to update row');
          }

          const data = await response.json();
          alert(data.message);
          window.location.reload();
      } catch (error) {
          console.error('Error:', error);
          alert('Failed to update data');
      }
  };

  fetchData();
  // exitEditingMode();
};

const handleSaveSaasInfoRow = ({ exitEditingMode, values }) => {
  const fetchData = async () => {
      try {
          const response = await fetch(`${sassInfoUpdateUrl}?email=${encodeURIComponent(email)}`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                saas_account: values.saas_account,
                application: values.application,
                service: values.service,
                billing_type: values.billing_type,
                billing_cycle: values.billing_cycle,
                mfa: values.mfa,
                purpose: values.purpose,
                plan_details: values.plan_details,
                master_user: values.master_user,
                login_mode: values.login_mode,
                renewal_date: values.renewal_date,
                card_holder: values.card_holder,
                card_number: values.card_number,
                billing_address: values.billing_address,
                remarks: values.remarks,
                tool_manager:values.tool_manager,
                tool_owner: values.tool_owner,
                account_owner: values.account_owner
              })
          });
          if (response.status === 400) {
            const errorData = await response.json();
            alert(errorData.error || 'A record with the same Account, Application, Service already exists.');
            return;
          }
          if (!response.ok) {
              throw new Error('Failed to update row');
          }
          const data = await response.json();
          alert(data.message);
          window.location.reload();
      } catch (error) {
          console.error('Error:', error);
          alert('Failed to update data');
      }
  };

  fetchData();
  // exitEditingMode();
};

const handleDeleteSaasInfoRow = (values) => {
  const fetchData = async () => {
    try {
      const response = await fetch(`${sassInfoDeleteUrl}?email=${encodeURIComponent(email)}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          saas_account: values.saas_account,
          application: values.application,
          service: values.service
        })
      });

      if (!response.ok) {
        throw new Error('Failed to delete row');
      }

      const data = await response.json();
      alert(data.message);
      window.location.reload(); 
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to delete data');
    }
  };

  fetchData();
};
const handleDeleteVendorBillingRow = (values) => {
  const fetchData = async () => {
    try {
      const response = await fetch(`${vendorBillingDeleteUrl}?email=${encodeURIComponent(email)}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          linked_account: values.account,
          date: values.date
        })
      });

      if (!response.ok) {
        throw new Error('Failed to delete row');
      }

      const data = await response.json();
      alert(data.message);
      window.location.reload(); 
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to delete data');
    }
  };

  fetchData();
};
const handleDeleteSaasBillingRow = (values) => {
  const fetchData = async () => {
    try {
      const response = await fetch(`${sassBillingDeleteUrl}?email=${encodeURIComponent(email)}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          saas_account: values.saas_account,
          application: values.application,
          service: values.service,
          payment_period: values.payment_period
        })
      });

      if (!response.ok) {
        throw new Error('Failed to delete row');
      }

      const data = await response.json();
      alert(data.message);
      window.location.reload(); 
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to delete data');
    }
  };

  fetchData();
};
const handleDeleteCostAnomalyConfigRow = (values) => {
  const fetchData = async () => {
    try {
      const response = await fetch(`${costAnomalyConfigDeleteUrl}?email=${encodeURIComponent(email)}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          account: values.accounts,
          application: values.applications,
          service: values.services
        })
      });

      if (!response.ok) {
        throw new Error('Failed to delete row');
      }

      const data = await response.json();
      alert(data.message);
      window.location.reload(); 
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to delete data');
    }
  };

  fetchData();
};

const handleSaveDomainRow = ({ exitEditingMode, values }) => {
  const fetchData = async () => {
      try {
          const response = await fetch(`${domainUpdateUrl}?email=${encodeURIComponent(email)}`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                domain: values.domain,
                sub_domain: values.sub_domain,
                data:values.data,
                application_id: values.application_id,
                description: values.description,
                status: values.status,
                dns_manager: values.dns_manager,
                dns_delegation: values.dns_delegation,
                waf: values.waf,
                cdn: values.cdn,
                review_due_date: values.review_due_date,
                requested_by: values.requested_by,
              })
          });

          if (!response.ok) {
              throw new Error('Failed to update row');
          }

          const data = await response.json();
          alert(data.message);
          window.location.reload();
      } catch (error) {
          console.error('Error:', error);
          alert('Failed to update data');
      }
  };

  fetchData();
  exitEditingMode();
};
  const handleSaveCostAnomalyRow = ({ exitEditingMode, values }) => {
    const updateCostAnomalyData = async () => {
        try {
            const response = await fetch(`${costAnomalyUpdateUrl}?email=${encodeURIComponent(email)}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    audit_date: values.audit_date,
                    account: values.account,
                    application: values.application,
                    service: values.service,
                    remarks: values.remarks
                })
            });

            if (!response.ok) {
                throw new Error('Failed to update cost anomaly data');
            }

            const data = await response.json();
            alert(data.message);
            window.location.reload();
        } catch (error) {
            console.error('Error:', error);
            alert('Failed to update cost anomaly data');
        }
    };

    updateCostAnomalyData();
    exitEditingMode();
};

const handleSaveCostAnomalyConfigRow = ({ exitEditingMode, values }) => {
  const updateCostAnomalyConfigData = async () => {
      try {
          const response = await fetch(`${costAnomalyConfigUpdateUrl}?email=${encodeURIComponent(email)}`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                  account: values.accounts,
                  application: values.applications,
                  service: values.services,
                  ref_amt: values.ref_amt,
                  deviation: values.deviation,
                  remarks: values.remarks
              })
          });

          if (!response.ok) {
              throw new Error('Failed to update cost anomaly config data');
          }

          const data = await response.json();
          alert(data.message);
          window.location.reload();
      } catch (error) {
          console.error('Error:', error);
          alert('Failed to update cost anomaly config data');
      }
  };

  updateCostAnomalyConfigData();
  exitEditingMode();
};
const handleSaveAppInfo = ({ exitEditingMode, values }) => {
  const fetchData = async () => {
      try {
          const response = await fetch(`${appInfoUpdateUrl}?email=${encodeURIComponent(email)}`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                  app_name: values.app_name,
                  devops_engineer: values.devops_engineer,
                  devops_coordinators: values.devops_coordinators,
                  pm: values.PM,
                  developers:values.developers
              })
          });

          if (!response.ok) {
              throw new Error('Failed to update row');
          }

          const data = await response.json();
          alert(data.message); 
          window.location.reload();
      } catch (error) {
          console.error('Error:', error);
          alert('Failed to update data'); 
      }
  };

  fetchData();
  exitEditingMode();
};
const SaveSquadCast = ({ exitEditingMode, values }) => {
  const updateSquadCastData = async () => {
      try {
          const response = await fetch(`${squadCastUpdateUrl}?email=${encodeURIComponent(email)}`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                  id: values.id,
                  remarks: values.remarks
              })
          });

          if (!response.ok) {
              throw new Error('Failed to update squadcast data');
          }

          const data = await response.json();
          alert(data.message);
          window.location.reload();
      } catch (error) {
          console.error('Error:', error);
          alert('Failed to update squadcast data');
      }
  };

  updateSquadCastData();
  exitEditingMode();
};
const SaveMicroServicesMaster= ({ exitEditingMode, values }) => {
  const fetchData = async () => {
      try {
          const response = await fetch(`${MSMUpdateUrl}?email=${encodeURIComponent(email)}`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                app: values.app,
                microservices_list: values.microservices_list,
              })
          });

          if (!response.ok) {
              throw new Error('Failed to update row');
          }

          const data = await response.json();
          alert(data.message);
          window.location.reload();
      } catch (error) {
          console.error('Error:', error);
          alert('Failed to update data');
      }
  };

  fetchData();
};
const DeleteMicroServicesMaster= (values) => {
  const fetchData = async () => {
      try {
          const response = await fetch(`${MSMDeleteUrl}?email=${encodeURIComponent(email)}`, {
              method: 'DELETE',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                app: values.app,
                
              })
          });

          if (!response.ok) {
              throw new Error('Failed to delete row');
          }

          const data = await response.json();
          alert(data.message);
          window.location.reload();
      } catch (error) {
          console.error('Error:', error);
          alert('Failed to delete data');
      }
  };

  fetchData();
};
const DeleteMisAppTools= (values) => {
  const fetchData = async () => {
      try {
          const response = await fetch(`${MATDeleteUrl}?email=${encodeURIComponent(email)}`, {
              method: 'DELETE',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                tool_name: values.tool_name,
                app:values.app,
              })
          });

          if (!response.ok) {
            const errorData = await response.json(); 
            throw new Error(errorData.error || 'Failed to delete row'); 
          }

          const data = await response.json();
          alert(data.message);
          window.location.reload();
      } catch (error) {
          console.error('Error:', error);
          alert(error.message || 'Failed to delete row');
      }
  };

  fetchData();
  // exitEditingMode();
};
const SaveMisAppTools= ({ exitEditingMode, values }) => {
  const fetchData = async () => {
      try {
          const response = await fetch(`${MATUpdateUrl}?email=${encodeURIComponent(email)}`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                tool_name: values.tool_name,
                app:values.app,
                instance_name: values.instance_name,
                details: values.details
              })
          });

          if (!response.ok) {
            const errorData = await response.json(); 
            throw new Error(errorData.error || 'Failed to create row'); 
          }

          const data = await response.json();
          alert(data.message);
          window.location.reload();
      } catch (error) {
          console.error('Error:', error);
          alert(error.message || 'Failed to create row');
      }
  };

  fetchData();
  // exitEditingMode();
};
const SaveMisAppServices = ({ exitEditingMode, values }) => {
  const fetchData = async () => {
      try {
          const response = await fetch(`${MASUpdateUrl}?email=${encodeURIComponent(email)}`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                app: editingValue['app'],
                microservice: values.microservice,
                microservice_type: values.microservice_type,
                image_name: values.image_name,
                deployment_name: values.deployment_name,
                details: values.details,
                base_url: values.base_url,
                info: values.info
              })
          });

          if (!response.ok) {
            const errorData = await response.json(); 
            throw new Error(errorData.error || 'Failed to create row'); 
          }

          const data = await response.json();
          alert(data.message);
          window.location.reload();
      } catch (error) {
          console.error('Error:', error);
          alert(error.message || 'Failed to create row');
      }
  };

  fetchData();

};
const DeleteMisAppServices = ( values ) => {
  const fetchData = async () => {
      try {
          const response = await fetch(`${MASDeleteUrl}?email=${encodeURIComponent(email)}`, {
              method: 'DELETE',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                app: values.app,
                microservice: values.microservice,
                microservice_type: values.microservice_type,
              })
          });

          if (!response.ok) {
            const errorData = await response.json(); 
            throw new Error(errorData.error || 'Failed to delete row'); 
          }

          const data = await response.json();
          alert(data.message);
          window.location.reload();
      } catch (error) {
          console.error('Error:', error);
          alert(error.message || 'Failed to delete row');
      }
  };

  fetchData();

};
const SaveMisAppDependencies = ({ exitEditingMode, values }) => {
  const fetchData = async () => {
      try {
          const response = await fetch(`${MADUpdateUrl}?email=${encodeURIComponent(email)}`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                app: editingValue['app'],
                microservice: values.microservice,
                dependent_app: editingValue['dependent_app'],
                dependent_service: values.dependent_service,
                dependent_type:values.dependent_type,
                details: values.details
              })
          });

          if (!response.ok) {
            const errorData = await response.json(); 
            throw new Error(errorData.error || 'Failed to create row'); 
          }

          const data = await response.json();
          alert(data.message);
          window.location.reload();
      } catch (error) {
          console.error('Error:', error);
          alert(error.message || 'Failed to create row');
      }
  };

  fetchData();
};
const DeleteMisAppDependencies = ( values ) => {
  const fetchData = async () => {
      try {
          const response = await fetch(`${MADDeleteUrl}?email=${encodeURIComponent(email)}`, {
              method: 'DELETE',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                app: values.app,
                microservice: values.microservice,
              })
          });

          if (!response.ok) {
            const errorData = await response.json(); 
            throw new Error(errorData.error || 'Failed to delete row'); 
          }

          const data = await response.json();
          alert(data.message);
          window.location.reload();
      } catch (error) {
          console.error('Error:', error);
          alert(error.message || 'Failed to delete row');
      }
  };

  fetchData();
};
const CreateToolsMaster= ({ exitCreatingMode, values }) => {
  const fetchData = async () => {
      try {
          const response = await fetch(`${TMCreateUrl}?email=${encodeURIComponent(email)}`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                tool_name: values.tool_name,
                
              })
          });

          if (!response.ok) {
            const errorData = await response.json(); 
            throw new Error(errorData.error || 'Failed to create row'); 
          }

          const data = await response.json();
          alert(data.message);
          window.location.reload();
      } catch (error) {
          console.error('Error:', error);
          alert(error.message || 'Failed to create row');
      }
  };

  fetchData();

};
const DeleteToolsMaster= ( values) => {
  const fetchData = async () => {
      try {
          const response = await fetch(`${TMDeleteUrl}?email=${encodeURIComponent(email)}`, {
              method: 'DELETE',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                tool_name: values.tool_name,
                
              })
          });

          if (!response.ok) {
            const errorData = await response.json(); 
            throw new Error(errorData.error || 'Failed to delete row'); 
          }

          const data = await response.json();
          alert(data.message);
          window.location.reload();
      } catch (error) {
          console.error('Error:', error);
          alert(error.message || 'Failed to delete row');
      }
  };

  fetchData();
};
const handleSaveAppServiceRow = ({ exitEditingMode, values }) => {
  const fetchData = async () => {
      try {
          const response = await fetch(`${appServiceUpdateUrl}?email=${encodeURIComponent(email)}`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                service_name: values.service_name,
                app_tag: values.app_tag,
                service_type: values.service_type,
                image_name: values.image_name,
                service_techstack: values.service_techstack,
                service_reponame: values.service_reponame,
                status: values.status,
                description: values.description
              })
          });

          if (!response.ok) {
              throw new Error('Failed to update row');
          }

          const data = await response.json();
          alert(data.message);
          window.location.reload();
      } catch (error) {
          console.error('Error:', error);
          alert('Failed to update data');
      }
  };

  fetchData();
  //exitEditingMode();
};
const handleSavePipelineRow = ({ exitEditingMode, values }) => {
  const fetchData = async () => {
      try {
          const response = await fetch(`${pipelineUpdateUrl}?email=${encodeURIComponent(email)}`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                job_name: values.job_name,
                service_name: values.service_name,
                job_type: values.job_type,
                image_path: values.image_path,
                cicd_reponame: values.cicd_reponame,
                app_tag: values.app_tag,
                service_repobranch: values.service_repobranch,
                cicd_branch: values.cicd_branch,
                cicd_techstack: values.cicd_techstack,
                job_url: values.job_url,
                service_url: values.service_url,
                status: values.status,
                description: values.description
              })
          });

          if (!response.ok) {
              throw new Error('Failed to update row');
          }

          const data = await response.json();
          alert(data.message);
          window.location.reload();
      } catch (error) {
          console.error('Error:', error);
          alert('Failed to update data');
      }
  };

  fetchData();
  //exitEditingMode();
};

  const onEditingRowSave = ({ exitEditingMode, values }) => {
    if (title === 'NodePing Data') {
        handleSaveRow({ exitEditingMode, values });
    } else if (title === 'Cost Anomaly Data') {
        handleSaveCostAnomalyRow({ exitEditingMode, values });
    } else if (title === 'Cost Anomaly Config Data') {
        handleSaveCostAnomalyConfigRow({ exitEditingMode, values });
    }else if (title === 'AppInfo Data') {
      handleSaveAppInfo({ exitEditingMode, values });
    }else if (title === 'SquadCast Data') {
      SaveSquadCast({ exitEditingMode, values });
   }else if (title==='Instance Data'){
      handleSaveInstanceRow({exitEditingMode, values});
   }
   else if (title==='Domains Data'){
    handleSaveDomainRow({exitEditingMode, values});
  }
  else if (title==='AWS Vendor Billing Data'){
    handleSaveVendorBillingRow({exitEditingMode, values});
  }
  else if (title==='Saas Billing Data'){
    handleSaveSaasBillingRow({exitEditingMode, values});
  }
  else if (title==='Saas Tool Info Data'){
    handleSaveSaasInfoRow({exitEditingMode, values});
  }
  else if (title==="App & Dependencies Data"){
    SaveMisAppDependencies({exitEditingMode, values})
   }
   else if (title==="App & Services Data"){
    SaveMisAppServices({exitEditingMode, values})
   }
   else if (title==="Micro Services Master Data"){
    SaveMicroServicesMaster({exitEditingMode, values})
   }
   else if (title==="App & Tools Data"){
    SaveMisAppTools({exitEditingMode, values})
   }
   else if (title==="App Service Data"){
    handleSaveAppServiceRow({exitEditingMode, values})
   }
   else if (title==="Pipeline Data"){
    handleSavePipelineRow({exitEditingMode, values})
   }
};
const onCreatingRowSave = ({ exitCreatingMode, values }) => { 
  if (title==='AWS Vendor Billing Data'){
    handleSaveVendorBillingRow({exitCreatingMode, values});
  }
  else if (title==='Saas Billing Data'){
    handleSaveSaasBillingRow({exitCreatingMode, values});
  }
  else if (title==='Saas Tool Info Data'){
    handleSaveSaasInfoRow({exitCreatingMode, values});
  }
  else if (title==="Micro Services Master Data"){
    SaveMicroServicesMaster({exitCreatingMode, values})
    }
  else if (title==="App & Services Data"){
      SaveMisAppServices({exitCreatingMode, values})
    }
  else if (title==="Tools Master Data"){
      CreateToolsMaster({exitCreatingMode, values})
    }
  else if (title==="App & Tools Data"){
      SaveMisAppTools({exitCreatingMode, values})
    }
   else if (title==="App & Dependencies Data"){
      SaveMisAppDependencies({exitCreatingMode, values})
    }
  else if (title==="App Service Data"){
      handleSaveAppServiceRow({exitCreatingMode, values})
    }
  else if (title==="Pipeline Data"){
      handleSavePipelineRow({exitCreatingMode, values})
  }
};
const openDeleteConfirmModal = (row) => {
  if (window.confirm('Are you sure you want to delete this user?')) {
    if (title==='Saas Billing Data'){
    handleDeleteSaasBillingRow(row.original);
    } else if (title==='Saas Tool Info Data'){
    handleDeleteSaasInfoRow(row.original);
    }
    else if (title==='AWS Vendor Billing Data'){
      handleDeleteVendorBillingRow(row.original);
    }
    else if (title==='Cost Anomaly Config Data'){
      handleDeleteCostAnomalyConfigRow(row.original);
    }
    else if (title==="Micro Services Master Data"){
      DeleteMicroServicesMaster(row.original);
      }
    else if (title==="App & Services Data"){
        DeleteMisAppServices(row.original);
      }
    else if (title==="Tools Master Data"){
        DeleteToolsMaster(row.original);
      }
    else if (title==="App & Tools Data"){
        DeleteMisAppTools(row.original);
      }
    else if (title==="App & Dependencies Data"){
        DeleteMisAppDependencies(row.original);
  }
}
};
  const table = useMaterialReactTable({
    columns,
    data,
    defaultColumn: { size: 40 },
    muiFilterTextFieldProps: {
      sx: { width: '100%', minWidth: 40 },
      placeholder: "",
    },
    muiTableBodyRowProps: ({ row }) =>{
      if(title == "SquadCast Data"){
        if (!row ||!row.original) {
          return {}; 
        }
        else{
          const ttrValue = row.original?.TTR;
          if (ttrValue !== undefined && ttrValue > 10.0){
            return{
              sx: {
                backgroundColor:'#cc7a00',
                
              }
            }
          }
        }
      }
    },
    onEditingRowSave,
    onCreatingRowSave,
    // editDisplayMode: "row",
    enableEditing: (hasWritePermission(title))&&(title === "NodePing Data" || title === "Cost Anomaly Data" || title === "Cost Anomaly Config Data" || title === "AppInfo Data" || title === "SquadCast Data" || title === "Instance Data" || title === "Domains Data" || 
    (title === "AWS Vendor Billing Data")|| title === "Saas Billing Data" || title === "Saas Tool Info Data" || title==="App & Dependencies Data" || title === "App & Tools Data"|| title === "Tools Master Data"|| title === "Micro Services Master Data"||title === "App & Services Data" ||
     title === "App Service Data" || title === "Pipeline Data"),
    enableDeleting: true,
    enableColumnActions: false,
    enableFullScreenToggle: false,
    initialState,
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Edit">
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        {(hasWritePermission(title))&&["AWS Vendor Billing Data", "Saas Tool Info Data", "Saas Billing Data", "Cost Anomaly Config Data","App & Tools Data", "Tools Master Data", "Micro Services Master Data","App & Services Data","App & Dependencies Data"].includes(title) && (
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        )}
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '8px',
          flexWrap: 'wrap',
        }}
      >
        {(hasWritePermission(title))&&["AWS Vendor Billing Data", "Saas Tool Info Data", "Saas Billing Data","App & Tools Data", "Tools Master Data", "Micro Services Master Data","App & Services Data","App & Dependencies Data","App Service Data","Pipeline Data"].includes(title) && (
          <Button
            variant="contained"
            startIcon={<AddIcon/>}
            onClick={() => {
              table.setCreatingRow(true);
            }}
            sx={{ marginRight: '8px' }}
          >
          Add
          </Button>
        )}
    
        <Typography
          variant="h4"
          sx={{ flexGrow: 1, textAlign: 'center' }}
        >
          {title}
        </Typography>
    
        <Button
          onClick={handleExportData}
          startIcon={<FileDownloadIcon style={{ color: 'green' }} />}
          style={{ color: 'green' }}
        >
          Export All Data
        </Button>
        {title === "NodePing Data" && (
        <Button
          onClick={handleSyncData}
          variant="contained"
          disabled={loading} 
          sx={{ color: 'white' }}
          
        >
          {loading ? <CircularProgress size={24} /> : 'Sync'}
        </Button>
        )}
      </Box>
    ),
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box
        m="10px"
        display="grid"
        sx={{
          "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 12" },
        }}
      >
        <Box m="10px 0 0 0" sx={{ overflowX: 'auto' }}>
          <MaterialReactTable table={table}  />
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default DataTable;